


























































































































import api from "@/api/index"; //ABP API接口
import { Vue, Component } from "vue-property-decorator";

import { WarehouseDto } from "@/api/appService";
import PagedTableView from "@/components/PagedTableView.vue";
import WareHouseEdit from "@/views/warehouse/warehouseLocation/edit.vue";

@Component({
  name: "WareHouseList",
  components: {
    WareHouseEdit,
    PagedTableView,
  },
})
export default class WareHouseList extends Vue {
  queryForm = {
    name: undefined,
    status: undefined,
  };
  editId = 0;

  // 获取表数据
  fetchData(params: any) {
    return api.wareHouse.getAll(params);
  }

  // 新建
  handleCreate() {
    this.editId = 0;
    (this.$refs.editForm as any).show = true;
  }

  // 编辑
  handleEdit(index: number, row: WarehouseDto) {
    // console.log("id=" + row!.id);
    this.editId = row.id!;
    (this.$refs.editForm as any).show = true;
    (this.$refs.editForm as any).form = row;
  }

  // 删除
  async handleDelete(index: number, row: WarehouseDto) {
    this.$confirm(
      (this as any).$l.getLanguageText("basic.deleteTip").toString(),
      (this as any).$l.getLanguageText("basic.tip").toString()
    ).then(async () => {
      await api.wareHouse
        .delete({
          id: row.id,
        })
        .then(() => {
          this.$message({
            type: "success",
            message: (this as any).$l
              .getLanguageText("basic.deleteSuccess")
              .toString(),
          });
        });
    });
  }

  // 库位管理
  handleLocation(index: number, row: WarehouseDto) {
    this.$router.push({
      name: "locationManagement",
      query: { id: row.id!.toString() },
    });
  }

  handleSelfLiftinPoints(index: number, row: WarehouseDto) {
    this.$router.push({
      name: "warehouseSelfLiftinPoint",
      query: { id: row.id!.toString() },
    });
  }
}
